import React from "react";
import { Features, Header } from "../../containers";

function Home() {
  return (
    <>
      <Header />
      <Features />
    </>
  );
}

export default Home;
